import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import Input from 'components/input/InputComponent';
import Button from 'components/ButtonComponent';

import {setCommentAction, setEmployeeAction} from 'redux/slices/inventory/inventorySlice';
import {postInventoryFileAsyncAction} from 'redux/slices/inventory/inventory-api-actions';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {AppRoute} from 'constants/routes';

import cl from 'styles/pages/signup.module.scss';

const InventoryAdd = () => {
  const navigate = useNavigate();
	const dispatch = useDispatch();
	
	// Флаг отправки
	const [isSended, setIsSended] = useState(false);
	
	// Состояние формы
	const [form, setForm] = useState({
		act: ""
	});

  const {alert, setCrumbs} = useContext(AppContext);

	// Перенаправление на главную, если нет доступа
	useEffect(() => {
		if(getUser().accessLevel < 2) navigate("/");
	}, []);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Инвентаризация", url: AppRoute.Inventory.ALL},
			{name: "Новый документ", url: ""},
		]);
	}, []);
	
	// Метод изменения данных в форме
	const changeHandler = (name, data) => {
		const f = Object.assign({}, form);
		f[name] = data;
		setForm(f);
	}
	
	// Отправка файла на сервер
	const sendFile = () => {
		// Проверка ввода
		if (form.act === "") {
			alert("Выберите файл", "danger");
			return false;
		}
		
		setIsSended(true);

		dispatch(setEmployeeAction(''));
		dispatch(setCommentAction(''));
		dispatch(postInventoryFileAsyncAction(form.act));
	}
	
	return (
		<>
      <title>Новая инвентаризация</title>			
			
			<div className={cl.main + " container"}>
				<form className={cl.form + " " + cl.withoutLabel}>
					<Input type="file" name="act" value={form.act} setValue={changeHandler}/>
					
					<div className="row">
						<div className="col-sm-12">
							<Button type="accent" onClick={sendFile} inProcess={isSended}>Заполнить акт</Button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default InventoryAdd;