import {Fragment, useContext, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import clsx from 'clsx';

import cl from 'styles/pages/[fieldId].module.scss';
import colors from 'styles/pages/[boardId].module.scss';
import toastMessageStyles from 'styles/components/toast-message.module.scss';

import {setInventoryDataAction} from 'redux/slices/inventory/inventorySlice';
import {setInventoryNewGoodInitialAmountsAsyncAction} from 'redux/slices/inventory/inventory-api-actions';
import {getInventoryDate} from 'redux/slices/inventory/selectors';

import {
  debounce,
  EMPLOYEE_SETTING_DELAY,
  VALIDATE_EMPLOYEE_DELAY,
  sortStringValues,
  getDateFromMySQLDate
} from 'helpers/utils';
import {AppContext} from 'providers/AppContextProvider';

import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import PopUp from 'components/popup/popup';

const TableRow = ({
  item,
  index,
  storage,
  goods,
  groups,
  addresses,
  paginationData,
  setPaginationData,
  outlinedRow,
  setOutlinedRow
}) => {
  const dispatch = useDispatch();

  const {alert} = useContext(AppContext);

  // Данные для всплывающего предупреждения об уже существующем товаре (ненужный дубликат)
  const [namePopUpData, setNamePopUpData] = useState({
    visible: false,
    repeatedItemIndex: null
  });
  const setNamePopUpVisible = (visible) => {
    setNamePopUpData((prevState) => ({
      ...prevState,
      visible
    }));
  };
  const [articlePopUpData, setArticlePopUpData] = useState({
    visible: false,
    repeatedItemIndex: null
  });
  const setArticlePopUpVisible = (visible) => {
    setArticlePopUpData((prevState) => ({
      ...prevState,
      visible
    }));
  };

  const items = useSelector((state) => state.inventory).inventory.data;
  const date = useSelector(getInventoryDate);

  const itemsNames = structuredClone(items).map((itm) => itm.name);
  const itemsArticles = structuredClone(items).map((itm) => itm.article);

  const itemOrderNumber = index + 1;

  // Список существующих и добавленных адресов в подстроках
  // для исключения их из выпадающего списка выбора адреса в подстроке
  const existingItemAddresses = Object.keys(item.datas.reduce((res, datasItem) => ({
    ...res,
    [datasItem.address_fact]: null,
    [datasItem.address_uchet]: null
  }), {})).filter((address) => !!address);
  const displayedAddresses = addresses.filter((address) => !existingItemAddresses.includes(address.value));

  // Получить список наименований для селекта для вновь добавленной строки (в самом низу)
  const filterSelectComponentOptions = (selectComponentName) => {
    // Отсеиваем те товары, которые уже есть в инвентаризации
    const filteredGoods = goods
      .filter((good) => !itemsNames.includes(good.name))
      .sort((a, b) => {
        const res = sortStringValues(a.value, b.value);
        return res;
      });
    const filteredArticles = filteredGoods.map((good) => ({
      id: good.b_group_id,
      name: good.article,
      value: good.article
    }));

    // Если в новой строке группа уже выбрана, то фильтруем список для селекта ещё и по группе
    if (item.b_group && groups.length) {
      const groupId = groups.find((gr) => gr.name === item.b_group).id;
      const filteredByGroupPlants = filteredGoods
        .filter((plant) => plant.b_group_id === groupId)
        .sort((a, b) => {
          const res = sortStringValues(a.value, b.value);
          return res;
        });
      const filteredByGroupArticles = filteredByGroupPlants.map((plant) => ({
        id: plant.b_group_id,
        name: plant.article,
        value: plant.article
      }));

      switch(selectComponentName) {
        case 'name':
          return filteredByGroupPlants;
        case 'article':
          return filteredByGroupArticles;
        default:
          return;
      };
    }

    switch(selectComponentName) {
      case 'name':
        return filteredGoods;
      case 'article':
        return filteredArticles;
      default:
        return;
    };
  };

  // Метод изменения наименования и артикула (и группы, если не выбрана)
  const changeValue = (index, selectElementName, value) => {
    const copiedItems = structuredClone(items);

    copiedItems[index][selectElementName] = value;

    if (selectElementName === 'name') {
      const plant = goods.find((plant) => plant.name === value);
      const plantArticle = plant.article;
      const businessGroupName = groups.find((group) => group.id === plant.b_group_id).name;

      copiedItems[index].article = plantArticle;
      copiedItems[index].b_group = businessGroupName;
    }
    if (selectElementName === 'article') {
      const plant = goods.find((plant) => plant.article === value);
      const plantName = plant.name;
      const businessGroupName = groups.find((group) => group.id === plant.b_group_id).name;

      copiedItems[index].name = plantName;
      copiedItems[index].b_group = businessGroupName;
    }

    dispatch(setInventoryDataAction(copiedItems));

    // Если товар выбран (наименование определено), то запрашиваем данные с полей
    if (copiedItems[index].name) {
      dispatch(setInventoryNewGoodInitialAmountsAsyncAction({
        name: copiedItems[index].name,
        storage,
        date: getDateFromMySQLDate(date)
      }));
    }
  };

  // Добавить колонку
  const addDataLine = (index) => {
    const copy = structuredClone(items);
    copy[index].datas.push({
      address_uchet: "",
      amount_uchet: "",
      address_fact: "",
      amount_fact: "",
      spread: "",
    });
    dispatch(setInventoryDataAction(copy));
  };

  // Удаление строки
  const deleteLine = ({index, i}) => {
    const copiedItems = structuredClone(items);
    copiedItems[index].datas.splice(i, 1);

    // Если это последняя подстрока, то из инвентаризации удаляется вся строка
    if (!copiedItems[index].datas.length) {
      copiedItems.splice(index, 1);
    }

    dispatch(setInventoryDataAction(copiedItems));
  };

  // Обработчик кнопки удаления строки
  const handleDeleteLineButtonClick = ({index, i}) => {
    const handler = () => {
      deleteLine({index, i});
      alert("Строка удалена", "success", 1000);
    };

    alert("Удалить строку?", "danger", 0, [
      {
        text: "Да",
        handler
      },
      {
        text: "Нет",
        handler: () => {
          alert("", "default", 1);
        },
        needToCloseImmediately: true
      }
    ]);
  };

  // Проверка, есть ли выбранное значение адреса в списке адресов выбранного склада
  const validateSelectedValue = (selectedValue) => {
    const optionsValues = addresses.map((option) => option.value);
    const isSelectedValueIsInOptions = optionsValues.includes(selectedValue);
    const isAnyOptionStartsWithValue = optionsValues.some((option) => option.startsWith(selectedValue));

    if (!isSelectedValueIsInOptions && !isAnyOptionStartsWithValue) {
      toast.error(`Адрес " ${selectedValue} " не содержится в списке адресов склада ${storage} !`, {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
    }
  };
  const validateSelectedValueDebounced = debounce((arg) => validateSelectedValue(arg), VALIDATE_EMPLOYEE_DELAY);

  // debounced setting into the state
  const setInventoryDataActionDebounced = debounce((arg) => dispatch(setInventoryDataAction(arg)), EMPLOYEE_SETTING_DELAY);

  // Изменение данных в формах
  const tableRowValueChangeHandler = (index, name, value) => {
    if (value && name === 'address_fact') {
      validateSelectedValueDebounced(value);
    }

    const clonedItems = structuredClone(items);
    clonedItems[index.index]["datas"][index.i][name] = value;

    const uchet = Number(clonedItems[index.index]["datas"][index.i]["amount_uchet"]);

    const fact = Number(clonedItems[index.index]["datas"][index.i]["amount_fact"]);

    clonedItems[index.index]["datas"][index.i]["spread"] = fact - uchet;

    // Подсчет общего факта
    let factAll = 0;
    clonedItems[index.index]["datas"].forEach((dt) => {
      factAll += Number(dt.amount_fact);
    });
    clonedItems[index.index].fact = factAll;
    clonedItems[index.index].total_fact = factAll;
    clonedItems[index.index].spread = Number(factAll) - Number(clonedItems[index.index].uchet);

    setInventoryDataActionDebounced(clonedItems);
  };

  // Проверка дублирования при вводе наименования в добавленной строке
  const checkNameRepeated = (value) => {
    if (value.trim()) {
      const nameAlreadyExists = itemsNames.includes(value);
      const repeatedItemIndex = items.findIndex((item) => item.name === value);

      if (nameAlreadyExists) {
        setNamePopUpData({
          visible: true,
          repeatedItemIndex
        });
      } else {
        setNamePopUpVisible(false);
      }
    }
  };

  // Проверка дублирования при вводе артикула в добавленной строке
  const checkArticleRepeated = (value) => {
    if (value.trim()) {
      const articleAlreadyExists = itemsArticles.includes(value);
      const repeatedItemIndex = items.findIndex((item) => item.article === value);

      if (articleAlreadyExists) {
        setArticlePopUpData({
          visible: true,
          repeatedItemIndex
        });
      } else {
        setArticlePopUpVisible(false);
      }
    }
  };

  // Переход к строке существующего товара ()
  const moveToExistingGoodLine = ({index, i}, inputName) => {
    // удалить вновь созданную строку с дубликатом
    deleteLine({index, i});

    const inputNamePageNumberMap = {
      'name': {
        repeatedItemPage: namePopUpData.repeatedItemIndex !== 0
          ? Math.ceil(namePopUpData.repeatedItemIndex / paginationData.itemsPerPage)
          : 1,
        repeatedItemIndex: namePopUpData.repeatedItemIndex
      },
      'article': {
        repeatedItemPage: articlePopUpData.repeatedItemIndex
          ? Math.ceil(articlePopUpData.repeatedItemIndex / paginationData.itemsPerPage)
          : 1,
        repeatedItemIndex: articlePopUpData.repeatedItemIndex
      }
    };

    // найти страницу, где товар расположен
    const existingGoodLocationPage = inputNamePageNumberMap[inputName].repeatedItemPage;

    // перейти на страницу с существующим товаром
    setPaginationData((prevState) => ({
      ...prevState,
      activePage: existingGoodLocationPage
    }));

    // подсветить строку
    setOutlinedRow(
      existingGoodLocationPage > paginationData.activePage
        ? inputNamePageNumberMap[inputName].repeatedItemIndex - 1
        : inputNamePageNumberMap[inputName].repeatedItemIndex
    );
    // выключить подсветку
    setTimeout(() => {
      setOutlinedRow(null);
    }, 2000);
  };

  return (
    <Fragment>
      {
        item.datas.map((dt, i) => (
          <tr key={i}>
            {
              i === 0 && (
                <>
                  {/* Ячейка: Номер по порядку */}
                  <td className={cl.center} rowSpan={item.datas.length}>
                    {itemOrderNumber}
                  </td>
    
                  {/* Ячейка: Наименование */}
                  <td
                    rowSpan={item.datas.length}
                    className={clsx(cl.itemName, {
                      [cl['inventory-table-row']]: outlinedRow === index,
                    })}
                  >
                    {item.name === "" ? (
                      <>
                        <CustomSearchSelect
                          inputName='name'
                          defaultValue={''}
                          options={filterSelectComponentOptions('name')}
                          onChange={(value) => changeValue(index, 'name', value)}
                          onInput={checkNameRepeated}
                          onFocus={checkNameRepeated}
                          placeholder='Наименование'
                        />
                        {
                          namePopUpData.visible && i === item.datas.length - 1 && (
                            <PopUp setPopUpVisible={setNamePopUpVisible}>
                              <span>
                                Такой товар уже есть в текущем документе в строке под номером:&nbsp;
                                <button onClick={() => moveToExistingGoodLine({index, i}, 'name')}>
                                  {namePopUpData.repeatedItemIndex + 1}
                                </button>
                              </span>
                            </PopUp>
                          )
                        }
                      </>
                    ) : (
                      <span
                        style={{ cursor: "pointer" }}
                        // onClick={() =>
                        //   navigate("/history?name=" + item.name)
                        // }
                      >
                        {item.name}
                      </span>
                    )}
                  </td>
    
                  {/* Ячейка: Артикул */}
                  <td
                    rowSpan={item.datas.length}
                    className={cl.itemArticle}
                  >
                    {item.name === "" ? (
                      <>
                        <CustomSearchSelect
                          inputName='article'
                          defaultValue={''}
                          options={filterSelectComponentOptions('article')}
                          onChange={(value) => changeValue(index, 'article', value)}
                          onInput={checkArticleRepeated}
                          onFocus={checkArticleRepeated}
                          placeholder='Артикул'
                        />
                        {
                          articlePopUpData.visible && i === item.datas.length - 1 && (
                            <PopUp setPopUpVisible={setArticlePopUpVisible}>
                              <span>
                                Такой товар уже есть в текущем документе в строке под номером:&nbsp;
                                <button onClick={() => moveToExistingGoodLine({index, i}, 'article')}>
                                  {articlePopUpData.repeatedItemIndex + 1}
                                </button>
                              </span>
                            </PopUp>
                          )
                        }
                      </>
                    ) : (
                      <span
                        style={{ cursor: "pointer" }}
                        // onClick={() =>
                        //   navigate("/history?name=" + item.name)
                        // }
                      >
                        {item.article}
                      </span>
                    )}
                  </td>
    
                  {/* Ячейка: Группа из Бизнес.ру */}
                  <td
                    rowSpan={item.datas.length}
                    className={cl.itemGroup}
                  >
                    {item.name === "" ? (
                      <CustomSearchSelect
                        inputName='b_group'
                        defaultValue={item.b_group}
                        options={groups}
                        onChange={(value) => changeValue(index, 'b_group', value)}
                        placeholder='Группа'
                      />
                    ) : (
                      item.b_group
                    )}
                  </td>
    
                  {/* Ячейка: Учёт (из xls файла) */}
                  <td className={cl.right} rowSpan={item.datas.length}>
                    {new Intl.NumberFormat("ru-RU").format(item.uchet)}
                  </td>
    
                  {/* Ячейка: Факт (из xls файла) */}
                  <td className={cl.right} rowSpan={item.datas.length}>
                    {new Intl.NumberFormat("ru-RU").format(item.fact)}
                  </td>
    
                  {/* Ячейка: Отклонение (из xls файла) */}
                  <td className={cl.right} rowSpan={item.datas.length}>
                    {new Intl.NumberFormat("ru-RU").format(item.spread)}
                  </td>
    
                  {/* Ячейка: Всего учёт */}
                  <td
                    className={cl.right + " " + colors.b7}
                    rowSpan={item.datas.length}
                  >
                    {new Intl.NumberFormat("ru-RU").format(
                      item.total_uchet
                    )}
                  </td>
                </>
              )
            }
  
            {/* Ячейка: Кол-во */}
            <td className={colors.b7 + " " + cl.right}>
              {new Intl.NumberFormat("ru-RU").format(dt.amount_uchet)}
            </td>
  
            {/* Ячейка: Адрес */}
            <td className={colors.b7 + " " + cl.address}>
              {dt.address_uchet}
            </td>
  
            {/* Ячейка: Всего факт */}
            {
              i === 0 && (
                <td
                  className={cl.right + " " + colors.b5}
                  rowSpan={item.datas.length}
                >
                  {new Intl.NumberFormat("ru-RU").format(item.total_fact)}
                </td>
              )
            }
  
            {/* Ячейка: Кол-во факт (Input) */}
            <td className={colors.b5 + " " + cl.right}>
              {
                item.provided === 1 ? (
                  new Intl.NumberFormat("ru-RU").format(dt.amount_fact)
                ) : (
                  <label className={cl.inputLabel}>
                    <input
                      type="text"
                      name="amount_fact"
                      defaultValue={dt.amount_fact}
                      placeholder="Факт"
                      autoComplete='off'
                      onChange={(evt) => tableRowValueChangeHandler({index, i}, 'amount_fact', evt.currentTarget.value)}
                    />
                  </label>
                )
              }
            </td>
  
            {/* Ячейка: Адрес */}
            <td className={colors.b5 + " " + cl.address}>
              {item.provided === 1 || !!dt.address_uchet ? (
                dt.address_fact
              ) : (
                <CustomSearchSelect
                  inputName='address_fact'
                  // defaultValue={dt.address_fact ? dt.address_fact : dt.address_uchet}
                  defaultValue={dt.address_fact}
                  options={displayedAddresses}
                  onChange={(value) => tableRowValueChangeHandler({index, i}, 'address_fact', value)}
                  executeChangeHandlerOnTypingValue={true}
                  placeholder='Адрес'
                />
              )}
            </td>
  
            {/* Ячейка: Отклонение */}
            <td className={colors.b5 + " " + cl.right}>
              {new Intl.NumberFormat("ru-RU").format(dt.spread)}
            </td>
  
            {/* Ячейка: Всего отклонение */}
            {i === 0 && (
              <td
                className={cl.right + " " + colors.b5}
                rowSpan={item.datas.length}
              >
                {!item.total_spread
                  ? 0
                  : new Intl.NumberFormat("ru-RU").format(
                      item.total_spread
                    )}
              </td>
            )}
  
            {/* КНОПКИ В КОНЦЕ СТРОКИ */}
            {/* Удалить строку */}
            {!item.provided && (
              <td className="iconed">
                <span
                  className="roundIcon material-icons"
                  onClick={() => handleDeleteLineButtonClick({index, i})}
                  title="Удалить строку"
                >
                  delete_outline
                </span>
              </td>
            )}
  
            {/* Добавить строку */}
            {i === 0 && !item.provided && (
              <td
                className={"iconed " + cl.bottom}
                style={{verticalAlign: 'bottom'}}
                rowSpan={item.datas.length}
              >
                <span
                  className="roundIcon material-icons"
                  onClick={() => addDataLine(index)}
                  title="Добавить строку"
                >
                  add
                </span>
              </td>
            )}
          </tr>
        ))
      }
    </Fragment>
  );
};

export default TableRow;
