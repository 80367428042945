import {useContext} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import clsx from 'clsx';

import {
  fetchSupplyDetailsAsyncAction,
  provideSupplyAsyncAction,
  saveSupplyAsyncAction
} from 'redux/slices/supplies/supplies-api-actions';
import {getNewSupplyDate, getSupply} from 'redux/slices/supplies/selectors';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {generateDate} from 'helpers/utils';

import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';
import {AppRoute} from 'constants/routes';

import styles from './styles.module.scss';
import toastMessageStyles from 'styles/components/toast-message.module.scss';

const SuppliesFloatPanel = ({
  setCommentModalActive,
  setIsPreloaderActive,
  addresses,
  displayedLines,
  setCheckValuesModalData,
  setIsCheckValuesModalActive
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const {alert} = useContext(AppContext);

  const supply = useSelector(getSupply);
  const documentNumber = supply.document_number;
  const lines = supply.form;
  const provided = supply.provided;
  const storage = supply.storage;
  const employee = supply.employee;
  const comment = supply.comment;
  const documentDate = supply.document_date;
  const newSupplyDate = useSelector(getNewSupplyDate);

  // Проверка ввода имени бригадира
  const checkEmployeeInserted = () => {
    if (!employee) {
      toast.error('Укажите бригадира!', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
      return false;
    } else {
      return true;
    }
  };

  // Проверка выбора склада
  const checkStorageSelected = () => {
    if (!storage) {
      toast.error('Выберите склад!', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
      return false;
    } else {
      return true;
    }
  };

  // Проверка правильности заполнения строк
  const checkLinesFilledCorrectly = () => {
    const incorrectlyFilledLines = {};

    displayedLines.forEach((line, lineIndex) => {
      const POSITION_AMOUNT_REG_EXP = /^\d+$/;
      const lineNumber = lineIndex + 1;
      const userPositions = line.userPositions;

      // Проверка на равенство суммы введённых значений по адресам общему кол-ву
      const totalUserPositionAmount = userPositions.reduce((res, position) => res + Number(position.amount), 0);
      const isAmountsEqualsToTotal = totalUserPositionAmount === line.total_incoming_amount;

      if (!isAmountsEqualsToTotal) {
        if (incorrectlyFilledLines[lineNumber]) {
          incorrectlyFilledLines[lineNumber].amountNotEqualsToTotal = true;
        } else {
          incorrectlyFilledLines[lineNumber] = {
            amountNotEqualsToTotal: true
          };
        }
      }

      // Валидация каждой подстроки (адрес и кол-во)
      userPositions.forEach((userPosition) => {
        // Условия валидации значений
        const isAddressSelected = !!userPosition.address;
        const isAddressInStorage = addresses.map((address) => address.name).includes(userPosition.address);
        const isAmountInserted = !!userPosition.amount;
        const isAmountNumeric = POSITION_AMOUNT_REG_EXP.test(userPosition.amount);

        // Следует ли показывать соответствующую ошибку
        const shouldAddressNotSelectedErrorBeShown = !isAddressSelected;
        const shouldAddressNotInStorageErrorBeShown = isAddressSelected && !isAddressInStorage;
        const shouldAmountNotInsertedErrorBeShown = !isAmountInserted;
        const sholudAmountNotNumericErrorBeShown = isAmountInserted && !isAmountNumeric;
        
        if (shouldAddressNotSelectedErrorBeShown) {
          if (incorrectlyFilledLines[lineNumber]) {
            incorrectlyFilledLines[lineNumber].addressNotSelected = true;
          } else {
            incorrectlyFilledLines[lineNumber] = {
              addressNotSelected: true
            };
          }
        }
        if (shouldAddressNotInStorageErrorBeShown) {
          if (incorrectlyFilledLines[lineNumber]) {
            incorrectlyFilledLines[lineNumber].addressNotInStorage = true;
          } else {
            incorrectlyFilledLines[lineNumber] = {
              addressNotInStorage: true
            };
          }
        }

        if (shouldAmountNotInsertedErrorBeShown) {
          if (incorrectlyFilledLines[lineNumber]) {
            incorrectlyFilledLines[lineNumber].amountNotInserted = true;
          } else {
            incorrectlyFilledLines[lineNumber] = {
              amountNotInserted: true
            };
          }
        }
        if (sholudAmountNotNumericErrorBeShown) {
          if (incorrectlyFilledLines[lineNumber]) {
            incorrectlyFilledLines[lineNumber].amountNotNumeric = true;
          } else {
            incorrectlyFilledLines[lineNumber] = {
              amountNotNumeric: true
            };
          }
        }
      });

      // Если в строке (в товаре) есть ошибка хоть в одной из подстрок,
      // то добавляем данные о товаре к объекту incorrectlyFilledLines
      if (incorrectlyFilledLines[lineNumber]) {
        incorrectlyFilledLines[lineNumber] = {
          ...incorrectlyFilledLines[lineNumber],
          line
        };
      }
    });

    if (Object.keys(incorrectlyFilledLines).length) {
      setCheckValuesModalData(incorrectlyFilledLines);
      setIsCheckValuesModalActive(true);
      return false
    } else {
      return true;
    }
  };

  // Сохранение Поступления
  const saveSupply = async () => {
    const result = await dispatch(saveSupplyAsyncAction({
      id,
      body: {
        form: lines,
        document: {
          date: newSupplyDate ? `${newSupplyDate}${documentDate.slice(-9)}` : documentDate
        },
        storage,
        employee,
        comment
      }
    }));

    if (result.error) {
      toast.error('Ошибка сохранения', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
    } else {
      toast.success('Поступление сохранено', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
    }
  };

  // Обработчик кнопки сохранения Поступления
  const handleSaveButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);
      await saveSupply();
      dispatch(fetchSupplyDetailsAsyncAction(id));
      setIsPreloaderActive(false);
    };

    alert('Сохранить документ?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Проведение Поступления
  const provideSupply = async () => {
    const result = await dispatch(provideSupplyAsyncAction({
      storage,
      type: DocumentTypeNameMap[DocumentType.SUPPLY],
      form: lines,
      document: {
        number: documentNumber,
        // date: generateDate(), // Установка текущей даты (она записывается в Историю движений)
        date: newSupplyDate ? `${newSupplyDate}${documentDate.slice(-9)}` : documentDate, // Установка даты документа по дате в Бизнес.ру
        form_id: id
      },
    }));

    if (result.error) {
      toast.error('Ошибка проведения', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
    } else {
      toast.success('Поступление проведено', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
    }
  };

  // Обработчик кнопки проведения Поступления
  const handleProvideDocumentButtonClick = () => {
    const handler = async () => {
      const linesFilledCorrectly = checkLinesFilledCorrectly();
      const employeeInserted = checkEmployeeInserted();
      const storageSelected = checkStorageSelected();

      if (employeeInserted && storageSelected && linesFilledCorrectly) {
        setIsPreloaderActive(true);
        await saveSupply();
        await provideSupply();
        navigate(AppRoute.Document.SUPPLIES);
        setIsPreloaderActive(false);
      }
    };

    alert('Провести документ?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  const handleOpenCommentModalClick = () => {
    setCommentModalActive(true);
  };

  const tmpButtonHandler = () => toast.success('Кнопка временно не работает', {
    position: 'bottom-right',
    className: toastMessageStyles['toast-message']
  });

  return (
    <div className={clsx(styles.floatPanel, styles.controlsPanel)}>
      {/* Кнопка удаления документа */}
      {/* {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={handleDeleteDocumentButtonClick}
            title="Удалить"
          >
            delete_sweep
          </span>
        )
      } */}

      {/* Кнопка проведения документа в системе */}
      {
        // canUserUpdate && provided && !done && (
        //   <>
        //     {/* Кнопка отмены проведения */}
        //     <span
        //       className="roundIcon material-icons dangerIcon"
        //       onClick={handleDenyDocumentProvidingButtonClick}
        //       title="Отменить проведение"
        //     >
        //       undo
        //     </span>
            
        //     {/* Кнопка проведения в Бизнес.ру */}
        //     {/* form.provided === 1 */}
        //     <span
        //       className="roundIcon material-icons successIcon"
        //       onClick={handleBruProvideButtonClick}
        //       title="Провести в Базе"
        //     >
        //       done
        //     </span>
        //   </>
        // )
      }
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleProvideDocumentButtonClick}
            title="Провести в Системе"
          >
            checklist
          </span>
        )
      }

      {/* Кнопка копирования документа */}
      {/* {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleCopyDocumentButtonClick}
            title="Скопировать документ"
          >
            copy
          </span>
        )
      } */}

      {/* Кнопка сохранения документа */}
      {
        canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleSaveButtonClick}
            title="Сохранить документ"
          >
            save
          </span>
        )
      }

      {/* Кнопка скачивания документа */}
      {
        id && (
          <span
            className="roundIcon material-icons"
            onClick={tmpButtonHandler}
            title="Скачать"
          >
            download
          </span>
        )
      }

      {/* Кнопка открытия модального окна примечания */}
      <span
        className="roundIcon material-icons"
        onClick={handleOpenCommentModalClick}
        title="Примечание"
      >
        maps_ugc
      </span>

      {/* Кнопка перехода в руководство пользователя */}
      <span
        className="roundIcon material-icons"
        onClick={tmpButtonHandler}
        title="Инструкция"
      >
        quiz
      </span>
    </div>
  );
};

export default SuppliesFloatPanel;
