import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import fetcher, {loadingStatus} from 'helpers/fetcher';
import {Map} from 'constants/endpoints';
import {
  fetchArchiveInventoriesAsyncAction,
  fetchInventoriesAsyncAction,
  fetchInventoryAsyncAction,
  setCommentFilesAsyncAction,
  setInventoryNewGoodInitialAmountsAsyncAction
} from './inventory-api-actions';

// TODO: это дубль экшена fetchAddresses
// нужно переписать на запрос с параметром типа ?storage=storage
// и оставить унифицированный экшен
const fetchAddresses = createAsyncThunk('inventory/getAllAddresses', async () => {
  const response = await fetcher(Map.GET_ALL_ADDRESSES);
  return response.json();
});

// TODO: это дубль экшена fetchMapFields
// нужно переписать на запрос с параметром типа ?storage=storage
// и оставить унифицированный экшен
const fetchFields = createAsyncThunk('inventory/getAllFields', async () => {
  const response = await fetcher(Map.GET_ALL_FIELDS);
  return response.json();
});

const initialState = {
  inventories: {
    status: loadingStatus.IDLE,
    data: [],
    archive: [],
    error: null,
  },
  inventory: {
    status: loadingStatus.IDLE,
    id: null,
    storage: '',
    provided: null,
    isCopy: null,
    employee: '',
    date: '',
    documentNumber: '',
    comment: '',
    commentFiles: [],
    filename: '',
    data: [],
  },
  addresses: {
    status: loadingStatus.IDLE,
    data: [],
  },
  fields: {
    status: loadingStatus.IDLE,
    data: [],
  },
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setInventoryDataAction: (state, action) => {
      state.inventory.data = action.payload;
    },
    setCommentAction: (state, action) => {
      state.inventory.comment = action.payload;
    },
    setCommentFilesAction: (state, action) => {
      state.inventory.commentFiles = action.payload;
    },
    setEmployeeAction: (state, action) => {
      state.inventory.employee = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInventoriesAsyncAction.pending, (state) => {
        state.inventories.status = loadingStatus.LOADING;
      })
      .addCase(fetchInventoriesAsyncAction.fulfilled, (state, action) => {
        state.inventories.status = loadingStatus.SUCCEEDED;
        state.inventories.data = action.payload.result;
      })
      .addCase(fetchInventoriesAsyncAction.rejected, (state, action) => {
        state.inventories.status = loadingStatus.FAILED;
        state.inventories.error = `${action.error.name}: ${action.error.message}`;
      })
      .addCase(fetchInventoryAsyncAction.pending, (state) => {
        state.inventory.status = loadingStatus.LOADING;
      })
      .addCase(fetchInventoryAsyncAction.fulfilled, (state, action) => {
        if (action.payload.result.length) {
          state.inventory.status = loadingStatus.SUCCEEDED;
          state.inventory.data = action.payload.result;
          state.inventory.id = action.payload.result[0].inventory_id;
          state.inventory.storage = action.payload.result[0].storage;
          state.inventory.provided = action.payload.result[0].provided;
          state.inventory.isCopy = action.payload.result[0].is_copy;
          state.inventory.filename  = action.payload.result[0].filename;
          state.inventory.comment  = action.payload.result[0].comment;
          state.inventory.commentFiles  = action.payload.result[0].files;
          state.inventory.employee  = action.payload.result[0].employee;
          state.inventory.date  = action.payload.result[0].date;
          state.inventory.documentNumber  = action.payload.result[0].document_number;
        }
      })
      .addCase(fetchAddresses.pending, (state) => {
        state.addresses.status = loadingStatus.LOADING;
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.addresses.status = loadingStatus.SUCCEEDED;
        state.addresses.data = action.payload.result;
      })
      .addCase(fetchFields.pending, (state) => {
        state.addresses.status = loadingStatus.LOADING;
      })
      .addCase(fetchFields.fulfilled, (state, action) => {
        state.addresses.status = loadingStatus.SUCCEEDED;
        // const fieldList = action.payload.result.filter((field) => field.storage === state.inventory.data[0].storage);
        // console.log('/map/getAllFields', fieldList);
        // state.addresses.data = fieldList;
      })
      .addCase(fetchArchiveInventoriesAsyncAction.pending, (state) => {
        state.inventories.status = loadingStatus.LOADING;
      })
      .addCase(fetchArchiveInventoriesAsyncAction.fulfilled, (state, action) => {
        state.inventories.status = loadingStatus.SUCCEEDED;
        state.inventories.archive = action.payload.result;
      })
      .addCase(setCommentFilesAsyncAction.fulfilled, (state, action) => {
        const files = action.payload.files;
        state.inventory.commentFiles = [...state.inventory.commentFiles, ...files];
      })
      .addCase(setInventoryNewGoodInitialAmountsAsyncAction.fulfilled, (state, action) => {
        const plantName = action.payload.plantName;
        const amounts = action.payload.data.amounts;
        const inventoryItems = [...state.inventory.data];
        const selectedItemIndex = inventoryItems.findIndex((item) => item.name === plantName);

        const emptyDatas = [
          {
            address_uchet: '',
            amount_uchet: '',
            address_fact: '',
            amount_fact: '',
            spread: 0
          }
        ];
        const updatedInventoryDatas = amounts.length ? amounts.map((amountsItem) => ({
          address_uchet: amountsItem.address,
          amount_uchet: amountsItem.amount,
          address_fact: amountsItem.address,
          amount_fact: '',
          spread: -amountsItem.amount
        })) : emptyDatas;

        inventoryItems[selectedItemIndex].datas = updatedInventoryDatas;
        inventoryItems[selectedItemIndex].total_uchet = amounts.reduce((res, item) => res + Number(item.amount), 0);
        state.inventory.data = inventoryItems;
      });
  }
});

const setInventoryDataAction = inventorySlice.actions.setInventoryDataAction;
const setCommentAction = inventorySlice.actions.setCommentAction;
const setCommentFilesAction = inventorySlice.actions.setCommentFilesAction;
const setEmployeeAction = inventorySlice.actions.setEmployeeAction;

export {
  fetchAddresses,
  fetchFields,
  setInventoryDataAction,
  setCommentAction,
  setCommentFilesAction,
  setEmployeeAction
};
