export const getRealizationHead = (state) => state.realizations.head;
export const getRealizationItems = (state) => state.realizations.realizationItems;
export const getRealizationCommentFiles = (state) => state.realizations.commentFiles;
export const getRealizationDocumentNumber = (state) => state.realizations.documentNumber;
export const getRealizationDocumentDate = (state) => state.realizations.documentDate;
export const getRealizationDocumentNewDate = (state) => state.realizations.documentNewDate;
export const getRealizationProvidedStatus = (state) => state.realizations.provided;
export const getRealizationEmployee = (state) => state.realizations.employee;
export const getRealizationAuthor = (state) => state.realizations.author;
export const getRealizationComment = (state) => state.realizations.comment;
export const getRealizationDoneStatus = (state) => state.realizations.done;
export const getRealizationsList = (state) => state.realizations.realizationsList;
