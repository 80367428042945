import Modal from 'components/Modal';

import style from './styles.module.scss';

const SupplyCheckValuesModal = ({
  data,
  isActive,
  setIsActive
}) => {
  const handleCopyEmptyLinesButtonClick = () => {
    navigator.clipboard.writeText(Object.keys(data).join(' '));
  };

  const CopyEmptyRowsListButton = () => (
    <span
      className="roundIcon material-icons"
      onClick={handleCopyEmptyLinesButtonClick}
      title="Скопировать строки"
    >
      copy
    </span>
  );

  return (
    <Modal isActive={isActive} setIsActive={setIsActive}>
      <div className={style.wrapper}>
        <h3 className={style.title}>
          {'Некорректно заполненные строки: '}
          <CopyEmptyRowsListButton />
        </h3>
        <table className={style.table}>
          <tr className={style.row}>
            <td className={style.cell}>Номер строки</td>
            <td className={style.cell}>Наименование</td>
            {
              Object.values(data).every((item) => (
                !item.amountNotInserted &&
                !item.amountNotNumeric &&
                !item.addressNotSelected &&
                !item.addressNotInStorage
              )) &&
              Object.values(data).some((item) => item.amountNotEqualsToTotal) ? (
                <>
                  <td className={style.cell}>Общее кол-во по адресам</td>
                </>
              ) : (
                <>
                  <td className={style.cell}>Кол-во</td>
                  <td className={style.cell}>Адрес</td>
                </>
              )
            }
          </tr>
          {
            Object.keys(data).map((itemNumber) => (
              <tr className={style.row}>
                <td className={style.cell}>
                  {`${itemNumber} `}
                </td>
                <td className={style.cell}>
                  {data[itemNumber].line.name}
                </td>
                {
                  !data[itemNumber].amountNotInserted &&
                  !data[itemNumber].amountNotNumeric &&
                  !data[itemNumber].addressNotSelected &&
                  !data[itemNumber].addressNotInStorage &&
                  data[itemNumber].amountNotEqualsToTotal ? (
                    <td className={style.cell}>
                      <span>Не совпадает с общим поступившим</span><br/>
                    </td>
                  ) : (
                    <>
                      <td className={style.cell}>
                        {
                          data[itemNumber].amountNotInserted && (
                            <>
                              <span>Не введено</span><br/>
                            </>
                          )
                        }
                        {
                          data[itemNumber].amountNotNumeric && (
                            <>
                              <span>Нечисловое значение</span><br/>
                            </>
                          )
                        }
                      </td>
                      <td className={style.cell}>
                        {
                          data[itemNumber].addressNotSelected && (
                            <>
                              <span>Не выбран</span><br/>
                            </>
                          )
                        }
                        {
                          data[itemNumber].addressNotInStorage && (
                            <>
                              <span>Не существует</span><br/>
                            </>
                          )
                        }
                      </td>
                    </>
                  )
                }
              </tr>
            ))
          }
        </table>
      </div>
    </Modal>
  );
};

export default SupplyCheckValuesModal;
