
import {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import RealizationsTable from 'components/archive/RealizationsTable';

import {getRealizationsList} from 'redux/slices/realizations/selectors';
import {fetchArchiveRealizationsListAsyncAction} from 'redux/slices/realizations/realizations-api-actions';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';

const Index = () => {
	const dispatch = useDispatch();

	const userLevel = getUser().accessLevel;

	const {alert, setCrumbs} = useContext(AppContext);

	const realizations = useSelector(getRealizationsList);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Архивы", url: "/archive"},
			{name: "Отгрузка", url: ""}
		]);
	}, []);

	// Запрос данных с сервера
	useEffect(() => {
		dispatch(fetchArchiveRealizationsListAsyncAction());
	}, []);
	
	return (
		<>
			<div className="cabinetMain">
				<RealizationsTable
					Realizations={realizations}
					alert={alert}
					userLevel={userLevel}
				/>
			</div>
		</>
	);
};

export default Index;
