import {useContext} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import CustomSearchSelect from 'components/custom-search-select/custom-search-select';

import {
  getNewSupplyDate,
  getSupply
} from 'redux/slices/supplies/selectors';
import {setSupplyFormAction} from 'redux/slices/supplies/supplies';
import {
  addSuppliesItemAsyncAction,
  deleteSuppliesItemAsyncAction,
  fetchSupplyDetailsAsyncAction,
  saveSupplyAsyncAction
} from 'redux/slices/supplies/supplies-api-actions';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';

import styles from './styles.module.scss';

const SupplyTableRow = ({
  supplyId,
  lineIndex,
  line,
  storage,
  addresses,
  provided,
  setIsPreloaderActive
}) => {
  const dispatch = useDispatch();
  const {id} = useParams();

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const {alert} = useContext(AppContext);

  const supply = useSelector(getSupply);
  const lines = supply.form;
  const documentDate = supply.document_date;
  const newSupplyDate = useSelector(getNewSupplyDate);
  const employee = supply.employee;
  const comment = supply.comment;

  const existingLineAddresses = line.userPositions
    .map((lineAddress) => lineAddress.address)
    .filter((filteredLineAddress) => !!filteredLineAddress);

  const displayedLineAddresses = addresses.filter((address) => !existingLineAddresses.includes(address.name));

  // Обработчик поля ввода кол-ва
  const handleAmountInputChange = (innerLineIndex, amount) => {
    const updatedLines = structuredClone(lines);

    updatedLines[innerLineIndex].userPositions[0].amount = amount;

    dispatch(setSupplyFormAction(updatedLines));
  };

  // Обработчик селекта выбора адреса в подстроке
  const handleAddressSelectElementChange = (innerLineIndex, address) => {
    const updatedLines = structuredClone(lines);

    updatedLines[innerLineIndex].userPositions[0].address = address;

    dispatch(setSupplyFormAction(updatedLines));
  };

  // Обработчик добавления подстроки
  const handleAddSubstringButtonClick = async () => {
    const newLine = lines.find((item) => item.article === line.article);

    await dispatch(saveSupplyAsyncAction({
      id,
      body: {
        form: lines,
        document: {
          date: newSupplyDate ? `${newSupplyDate}${documentDate.slice(-9)}` : documentDate
        },
        storage,
        employee,
        comment
      }
    }));

    await dispatch(addSuppliesItemAsyncAction(newLine));
    dispatch(fetchSupplyDetailsAsyncAction(supplyId));
  };

  // Обработчик удаления подстроки
  const handleDeleteSubstringButtonClick = (supplies_item_id) => {
    const handler = async () => {
      await dispatch(saveSupplyAsyncAction({
        id,
        body: {
          form: lines,
          document: {
            date: newSupplyDate ? `${newSupplyDate}${documentDate.slice(-9)}` : documentDate
          },
          storage,
          employee,
          comment
        }
      }));

      await dispatch(deleteSuppliesItemAsyncAction(supplies_item_id));
      dispatch(fetchSupplyDetailsAsyncAction(supplyId));
    };

    alert('Удалить площадку?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  return (
    <>
      {
        line.userPositions.map((position, positionIndex, userPositions) => (
          <tr key={positionIndex}>
            {
              positionIndex === 0 && (
                <>
                  {/* Ячейка № п/п */}
                  <td rowSpan={userPositions.length}>{lineIndex + 1}</td>
                  
                  {/* Ячейка "Наименование" */}
                  <td
                    rowSpan={userPositions.length}
                    className={styles.name}
                  >
                    {line.name}
                  </td>
                  
                  {/* Ячейка "Артикул" */}
                  <td
                    rowSpan={userPositions.length}
                  >
                    {line.article}
                  </td>
                  
                  {/* Ячейка "Группа" */}
                  <td rowSpan={userPositions.length}>{line.b_group}</td>
                  
                  {/* Ячейка "Поступило всего" */}
                  <td
                    rowSpan={userPositions.length}
                    className={styles.right}
                  >
                    {new Intl.NumberFormat("ru-RU").format(line.total_incoming_amount)}
                  </td>
                </>
              )
            }

            {/* Ячейка ввода поступившего кол-ва */}
            <td className={styles.right}>
              {
                !provided && canUserUpdate ? (
                  <input
                    type='text'
                    name='amount'
                    placeholder='Кол-во'
                    value={position.amount}
                    onChange={(evt) => handleAmountInputChange(position.lineIndex, evt.currentTarget.value)}
                    autoComplete='off'
                  />
                ) : (
                  new Intl.NumberFormat('ru-RU').format(
                    position.amount
                  )
                )
              }
            </td>

            {/* Ячейка выбора адреса прихода */}
            <td>
              <div className={styles.subLine}>
                {
                  provided === 0
                    ? (
                      <CustomSearchSelect
                        inputName='address'
                        defaultValue={position.address}
                        options={storage ? displayedLineAddresses : []}
                        onChange={(address) => handleAddressSelectElementChange(position.lineIndex, address)}
                        placeholder='Адрес'
                        executeChangeHandlerOnTypingValue={true}
                      />
                    )
                    : position.address
                }
                <div className={styles.buttonsWrapper}>
                  {
                    positionIndex === line.userPositions.length - 1 && !provided && storage && (
                      <td>
                        <span
                          className='material-icons'
                          onClick={() => handleAddSubstringButtonClick(position.supplies_item_id)}
                          title='Добавить адрес'
                        >
                          add
                        </span>
                      </td>
                    )
                  }
                  {
                    line.userPositions.length > 1 && !provided && (
                      <span
                        className='material-icons'
                        onClick={() => handleDeleteSubstringButtonClick(position.supplies_item_id)}
                        title='Удалить площадку'
                      >
                        delete_outline
                      </span>
                    )
                  }
                </div>
              </div>
            </td>
          </tr>
        ))
      }
    </>
  );
};

export default SupplyTableRow;
