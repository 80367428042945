export const EMPLOYEE_SETTING_DELAY = 1000;
export const VALIDATE_EMPLOYEE_DELAY = 1000;

export const debounce = (callback, delay) => {
  let timeoutId;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback.apply(this, arguments), delay);
  };
};

export const getInputValue = (evt) => {
  const inputType = evt.target.type;

  const InputType = {
    FILE: 'file',
    TEXT: 'text',
  };

  switch(inputType) {
    case InputType.TEXT:
      return evt.currentTarget.value;
    case InputType.FILE:
      return evt.target.files[0];
    default:
      return evt.target.value;
  }
};

export const getDateTimeStringFromDBValue = (initialValue) => {
  const currentDate = initialValue;
  const YYYY = `${currentDate[6]}${currentDate[7]}${currentDate[8]}${currentDate[9]}`;
  const MM = `${currentDate[3]}${currentDate[4]}`;
  const DD = `${currentDate[0]}${currentDate[1]}`;
  const HH = `${currentDate[11]}${currentDate[12]}`;
  const mm = `${currentDate[14]}${currentDate[15]}`;
  const dateTimeString = `${YYYY}-${MM}-${DD}T${HH}:${mm}:00.000Z`;

  return dateTimeString;
};

export const getOptionsList = (options, value) => {
  const processedOptions = structuredClone(options);

  // если есть значение, то отфильтровать опции, которые:
  if (value) {
    // - начинаются с него
    const startWithValueOptions = [];
    // - содержат его, но не начинаются с него
    const includesValueOptions = [];
    // - добавить все остальные
    const theRestOfOptions = [];

    // фильтруем
    for(let i = 0; i < processedOptions.length; i++) {
      if (processedOptions[i].value) {
        if (processedOptions[i].value.toLowerCase().startsWith(value.toLowerCase())) {
          startWithValueOptions.push(processedOptions[i]);
        }
        if (processedOptions[i].value.toLowerCase().includes(value.toLowerCase()) && !processedOptions[i].value.toLowerCase().startsWith(value.toLowerCase())) {
          includesValueOptions.push(processedOptions[i]);
        }
        if (!processedOptions[i].value.toLowerCase().includes(value.toLowerCase()) && !processedOptions[i].value.toLowerCase().startsWith(value.toLowerCase())) {
          theRestOfOptions.push(processedOptions[i]);
        }
      }
    }

    // собираем
    const displayedOptions = [
      ...startWithValueOptions,
      ...includesValueOptions,
      ...theRestOfOptions
    ];

    return displayedOptions;
  } else {
    // если значения нет, то вернуть изначальные опции
    return processedOptions;
  }
};

export const generateDate = () => {
  const date = new Date();

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const documentDate = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;

  return documentDate;
};

export const sortStringValues = (a, b) => {
  const russianLetters = {
    'а': 1,
    'б': 2,
    'в': 3,
    'г': 4,
    'д': 5,
    'е': 6,
    'ё': 7,
    'ж': 8,
    'з': 9,
    'и': 10,
    'й': 11,
    'к': 12,
    'л': 13,
    'м': 14,
    'н': 15,
    'о': 16,
    'п': 17,
    'р': 18,
    'с': 19,
    'т': 20,
    'у': 21,
    'ф': 22,
    'х': 23,
    'ц': 24,
    'ч': 25,
    'ш': 26,
    'щ': 27,
    'ъ': 28,
    'ы': 29,
    'ь': 30,
    'э': 31,
    'ю': 32,
    'я': 33
  };

  if (russianLetters.hasOwnProperty(a[0].toLowerCase()) && russianLetters.hasOwnProperty(b[0].toLowerCase())) {
    if (russianLetters[a[0].toLowerCase()] === russianLetters[b[0].toLowerCase()]) {
      return russianLetters[a[1].toLowerCase()] - russianLetters[b[1].toLowerCase()];
    }
    return russianLetters[a[0].toLowerCase()] - russianLetters[b[0].toLowerCase()];
  } else {
    return 0;
  }
};

export const getHumanFormattedDate = (date) => {
  // date: "2024-11-27 12:04:00";
  const monthNumberNameMap = {
    '01': 'января',
    '02': 'февраля',
    '03': 'марта',
    '04': 'апреля',
    '05': 'мая',
    '06': 'июня',
    '07': 'июля',
    '08': 'августа',
    '09': 'сентября',
    '10': 'октября',
    '11': 'ноября',
    '12': 'декабря'
  };
  const year = `${date[0]}${date[1]}${date[2]}${date[3]}`;
  const month = `${date[5]}${date[6]}`;
  const day = `${date[8]}${date[9]}`;
  const humanFormattedDate = `${day} ${monthNumberNameMap[month]} ${year}`;

  return humanFormattedDate;
};

export const getDateFromMySQLDate = (dateTime) => {
  const dateTimeValues = dateTime.split(' ');
  const date = dateTimeValues[0];
  const time = dateTimeValues[1];
  const updatedDate = date.split('-').reverse().join('.');
  const updateDateTimeString = [updatedDate, time].join(' ');
  return updateDateTimeString;
};
