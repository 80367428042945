import {useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';

import Indicator from 'components/Indicator';

import {
  deleteDocumentAsyncAction,
  fetchDocumentsAsyncAction
} from 'redux/slices/documents/documents-api-actions';

import {AppRoute} from 'constants/routes';
import {DocumentTypeNameMap, DocumentType} from 'constants/document-type';
import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';

import styles from './styles.module.scss';

const DocumentsListItem = ({document, setIsPreloaderActive}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userLevel = getUser().accessLevel;

  const {alert} = useContext(AppContext);

  const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const CHARGES_LOCATION_REG_EXP = /charges/;
  const POSTINGS_LOCATION_REG_EXP = /postings/;
  const pathName = location.pathname;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);
  const isInCharges = CHARGES_LOCATION_REG_EXP.test(pathName);
  const isInPostings = POSTINGS_LOCATION_REG_EXP.test(pathName);

  const handleShowDocumentDetailsButtonClick = () => {
    const regularLocation = (isInCharges && AppRoute.Document.CHARGES) || (isInPostings && AppRoute.Document.POSTINGS);
    const archiveLocation = (isInCharges && AppRoute.Archive.CHARGES) || (isInPostings && AppRoute.Archive.POSTINGS);

    if (isInArchive) {
      navigate(`${archiveLocation}/${document.id}`);
    } else {
      navigate(`${regularLocation}/${document.id}`);
    }
  };

  const handleDeleteArchiveDocumentButtonClick = () => {
    const documentType = (isInCharges && DocumentTypeNameMap[DocumentType.CHARGE]) || (isInPostings && DocumentTypeNameMap[DocumentType.POSTING]);

    const handler = async () => {
      setIsPreloaderActive(true);
      await dispatch(deleteDocumentAsyncAction(document.id));
      dispatch(fetchDocumentsAsyncAction({
        isInArchive,
        documentType
      }));
      setIsPreloaderActive(false);
    };

    alert('Удалить архив?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  return (
    <tr>
      <td>{document.document_number}</td>
      <td>{`${document.type}${document.is_correction ? ' (коррекция)' : ''}`}</td>
      <td>{document.document_date.split(' ').slice(0, -1).join(' ')}</td>
      <td>{document.storage}</td>
      <td>
        <button
          className={clsx(styles.button, 'material-icons')}
          onClick={handleShowDocumentDetailsButtonClick}
        >
          <span className={styles.icon}>
            visibility
          </span>
        </button>
      </td>
      <td>
        {
          isInArchive ? (
            userLevel === 3 && (
              <button
                className={clsx(styles.button, 'material-icons')}
                onClick={handleDeleteArchiveDocumentButtonClick}
              >
                <span className={clsx(styles.icon, styles.close)}>
                  close
                </span>
              </button>
            )
          ) : (
            <Indicator type={document.provided === 1 ? 'success' : 'danger'}/>
          )
        }
      </td>
    </tr>
  );
};

export default DocumentsListItem;
