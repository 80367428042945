import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
// import html2canvas from 'html2canvas';
import {toast} from 'react-toastify';

import Input from 'components/input/InputComponent';
import Modal from 'components/Modal';
import Preloader from 'components/preloader/SpinPlaceholderComponent';
import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import RealizationCheckValuesModal from 'components/realization-check-values-modal/realization-check-values-modal';

import {
	fetchRealizationAsyncAction,
	setRealizationInitialAmountsAsyncAction
} from 'redux/slices/realizations/realizations-api-actions';
import {
	getRealizationAuthor,
	getRealizationComment,
	getRealizationCommentFiles,
	getRealizationDocumentDate,
	getRealizationDocumentNumber,
	getRealizationDoneStatus,
	getRealizationEmployee,
	getRealizationItems,
	getRealizationProvidedStatus
} from 'redux/slices/realizations/selectors';
import {fetchGroups} from 'redux/slices/business/businessSlice';
import {fetchAddresses} from 'redux/slices/inventory/inventorySlice';

import {
	setRealizationCommentAction,
	setRealizationCommentFilesAction,
	setRealizationDocumentNumberAction,
	setRealizationEmployeeAction,
	setRealizationItemsAction
} from 'redux/slices/realizations/realizations';

import {AppContext} from 'providers/AppContextProvider';
import {sortStringValues} from 'helpers/utils';
import {getUser} from 'helpers/storage';
import {restrictedGroupsIds} from 'constants/document-data';

import tcl from 'styles/pages/[fieldId].module.scss';
import styles from './styles.module.scss';

const RealizationEdit = () => {
	const {id} = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const router = {
		pathname: window.location.pathname,
		push: (p) => navigate(p),
		query: { type: (new URLSearchParams(window.location.search)).get("type") },
	};

	const {alert, setCrumbs} = useContext(AppContext);

	const userLevel = getUser().accessLevel;

	const addressList = useSelector((state) => state.inventory).addresses.data;
	const sortedAddresses = structuredClone(addressList).sort((a, b) => {
    const ADDRESS_REG_EXP = /^\d+/;
    const prevValue = a.value;
    const nextValue = b.value;

    if (ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return prevValue.match(ADDRESS_REG_EXP)[0] - nextValue.match(ADDRESS_REG_EXP)[0];
    }
    if (ADDRESS_REG_EXP.test(prevValue) && !ADDRESS_REG_EXP.test(nextValue)) {
      return -1;
    }
    if (!ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return 1;
    }
    
    return 0;
  });
	const groups = useSelector((state) => state.business).groups.data
    .filter((group) => !restrictedGroupsIds.includes(Number(group.id)));

	const toScreenShot = useRef();
	const [isPreloaderActive, setIsPreloaderActive] = useState(false);

	const realizationItems = useSelector(getRealizationItems);
	const commentFiles = useSelector(getRealizationCommentFiles);
	const provided = useSelector(getRealizationProvidedStatus);
	const done = useSelector(getRealizationDoneStatus);
	const employee = useSelector(getRealizationEmployee);
	const author = useSelector(getRealizationAuthor);
	const documentNumber = useSelector(getRealizationDocumentNumber);
	const documentDate = useSelector(getRealizationDocumentDate);
	const comment = useSelector(getRealizationComment);

	// Список уже выбранных наименований
	const itemsNames = structuredClone(realizationItems).map((itm) => itm.name);

	// Данные с сервера
	const [total, setTotal] = useState(0);

	// Классы таблицы
	const tableClasses = [tcl.mainTable, tcl.top0, "table", "table-responsive"];

	// Асинхронные данные из Бизнес.ру
	const [plantsNames, setPlantsNames] = useState([]);
	const [plantsArticles, setPlantsArticles] = useState([]);

	// Поле сортировки и направление
	const [sorted, setSorted] = useState("name");
	const [sortDir, setSortDir] = useState(true);

	// Модальное окно примечания
	const [modalIsActive, setModalIsActive] = useState(false);

	// Модальное окно со списком некорректно заполненных строк
  const [checkValuesModalData, setCheckValuesModalData] = useState([]);
  const [isCheckValuesModalActive, setIsCheckValuesModalActive] = useState(false);

	// Модальное окно товаров, которые невозможно списать при втором проведении
  const [unavailableGoodsModalActive, setUnavailableGoodsModalActive] = useState(false);
	const [unavailableForShiftingGoods, setUnavailableForShiftingGoods] = useState([]);

	// Запрос товаров
	useEffect(() => {		
		axios.get("good/all").then((response) => {
			setPlantsNames(response.data.result.goods.sort((a, b) => {
				if(a.name === b.name) return 0;
				else return a.name > b.name ? 1 : -1;
			}));
			setPlantsArticles(response.data.result.articles);
		});
	}, []);

	// Запись хлебных крошек
	useEffect(() => {
		if (realizationItems.length && documentNumber) {
			setCrumbs([
				{name: "Отгрузки", url: "/realizations"},
				{name: "№ " + documentNumber + " (редактирование)", url: ""}
			]);
		}
	}, []);

	// Подсчет общего кол-ва
	useEffect(() => {
		if (realizationItems.length) {
			let totalAll = 0;
			realizationItems.forEach((item) => {
				item.userPositions.forEach((position) => {
					if(!isNaN(parseInt(position.amount))) totalAll += parseInt(position.amount);
				});
			});
			setTotal(totalAll);
		}
	}, [realizationItems.length]);

	// Обработчик кнопки копирования номеров строк в модальном окне списка товаров,
  // которые невозможно отгрузить из-за недостаточного кол-ва в Бизнес.ру
  const handleCopyUnavailableGoodsLinesNumbers = () => {
    const linesNumbers = unavailableForShiftingGoods.map((good) => {
      const lineNumber = realizationItems.findIndex((item) => String(item.good_id) === String(good.good_id)) + 1;
      return lineNumber;
    });
    navigator.clipboard.writeText(linesNumbers.join(' '));
  };

	// Обработчик кнопки закрытия модального окна со списком товаров,
  // которые невозможно отгрузить из-за недостаточного кол-ва в Бизнес.ру
  const handleUnavailableGoodsModalCloseButtonClick = () => {
    setUnavailableForShiftingGoods([]);
    setUnavailableGoodsModalActive(false);
  };

	  // Обработчик клика по неактивной дате документа
		const handleInactiveDocumentDateClick = () => {
			toast.error('Если нужна другая дата - измените её в соответствующем документе в Бизнес.ру', {
				position: 'top-left'
			});
		};

	// Формирование списка адресов для выпадающего списка
	const getDisplayedAddresses = (index) => {
		const selectedAddresses = realizationItems[index].userPositions.map((position) => position.address);
		const displayedAddresses = sortedAddresses.filter((address) => !selectedAddresses.includes(address.value));

		return displayedAddresses;
	};

	// Сохранение данных
	const editRealization = async(redirect = true) => {
		// Валидация
		let formError = false;
		let errorNotified = false;
		const copy = structuredClone(realizationItems);
		copy.forEach((c, i) => {
			let error = false;

			// Проверка номера документа
			if(documentNumber === ""){
				formError = true;
				errorNotified = true;
				toast.error("Введите номер документа", {
					position: 'bottom-right'
				});
			}

			if(error){
				realizationItems[i].isError = true;
				setTimeout(() => {
					realizationItems[i].isError = false;
				}, 5000);
			}
		});

		if(formError) return false;

		// Сохранение документа на отгрузку
		setIsPreloaderActive(true)
		return axios.post("realizations/saveRealizationToFile/" + realizationItems[0].realization_id, {
			realization: copy,
			head: {number: realizationItems[0].number, customer: realizationItems[0].customer},
			documentNumber: documentNumber,
			documentDate: documentDate,
			filename: realizationItems[0].filename,
			provided: !redirect,
			comment: comment
		}).then((response) => {
			// return screenShot().then(() => {
			// 	if(redirect){
			// 		alert("Успешно сохранено", "success", 2000);
			// 	}
			// 	return true;
			// });

			return true;
		}).catch(() => {
			toast.error("Ошибка сохранения", {
        position: 'bottom-right'
      });
			return false;
		}).finally(() => setIsPreloaderActive(false));
	};

	// Удаление отгрузки
	const deleteRealization = () => {
		alert("Удалить отгрузку?", "danger", 0, [
			{
				text: "Да",
				handler: function(){
					axios.delete("realizations/deleteRealization/" + realizationItems[0].realization_id).then(() => {
						toast.success("Отгрузка успешно удалена", {
							position: 'bottom-right'
						});
						router.push("/realizations");
					}).catch(() => {
						toast.error("Ошибка удаления", {
							position: 'bottom-right'
						});
					});
				},
				needToCloseImmediately: true
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	};

	// Проверка ввода номера документа
  const checkDocumentNumberInserted = () => {
    if (!documentNumber) {
      toast.error('Укажите номер документа!', {
        position: 'bottom-right'
      });
      return false;
    } else {
      return true;
    }
  };

  // Проверка ввода имени бригадира
  const checkEmployeeInserted = () => {
    if (!employee) {
      toast.error('Укажите бригадира!', {
        position: 'bottom-right'
      });
      return false;
    } else {
      return true;
    }
  };

	// Проверка правильности заполнения строк
  const checkLinesFilledCorrectly = () => {
    const incorrectlyFilledLines = {};

    realizationItems.forEach((line, lineIndex) => {
      const POSITION_AMOUNT_REG_EXP = /^\d+$/;
      const lineNumber = lineIndex + 1;
      const userPositions = line.userPositions;
      const existingAmounts = line.amounts;

      // Валидация каждой подстроки (адрес и кол-во)
      userPositions.forEach((userPosition) => {
				const userPositionAmount = Number(userPosition.amount);
				const userPositionAddress = userPosition.address;
				const existingAmountsItem = existingAmounts.find((amountItem) => amountItem.address === userPositionAddress);

        // Условия валидации значений
        const isAddressSelected = !!userPositionAddress;
        const isAmountInserted = !!userPositionAmount;
        const isAmountNumeric = POSITION_AMOUNT_REG_EXP.test(userPositionAmount);
				const isSelectedAddressHasSelectedGood = !!existingAmountsItem;
				const isAmountWithinExistingValue = existingAmountsItem && userPositionAmount <= Number(existingAmountsItem.amount);

        // Следует ли показывать соответствующую ошибку
        const shouldAddressNotSelectedErrorBeShown = !isAddressSelected;
        const shouldAmountNotInsertedErrorBeShown = !isAmountInserted;
        const shouldAmountNotNumericErrorBeShown = isAmountInserted && !isAmountNumeric;
				const shouldSelectedAddressDoesNotHaveGoodErrorBeShown = !isSelectedAddressHasSelectedGood;
				const shouldAmountExceedsExistingValueErrorBeShown = isSelectedAddressHasSelectedGood && !isAmountWithinExistingValue;

        if (shouldAddressNotSelectedErrorBeShown) {
          if (incorrectlyFilledLines[lineNumber]) {
            incorrectlyFilledLines[lineNumber].addressNotSelected = true;
          } else {
            incorrectlyFilledLines[lineNumber] = {
              addressNotSelected: true
            };
          }
        }
				if (shouldSelectedAddressDoesNotHaveGoodErrorBeShown) {
          if (incorrectlyFilledLines[lineNumber]) {
            incorrectlyFilledLines[lineNumber].addressDoesNotHaveGood = true;
          } else {
            incorrectlyFilledLines[lineNumber] = {
              addressDoesNotHaveGood: true
            };
          }
        }

        if (shouldAmountNotInsertedErrorBeShown) {
          if (incorrectlyFilledLines[lineNumber]) {
            incorrectlyFilledLines[lineNumber].amountNotInserted = true;
          } else {
            incorrectlyFilledLines[lineNumber] = {
              amountNotInserted: true
            };
          }
        }
        if (shouldAmountNotNumericErrorBeShown) {
          if (incorrectlyFilledLines[lineNumber]) {
            incorrectlyFilledLines[lineNumber].amountNotNumeric = true;
          } else {
            incorrectlyFilledLines[lineNumber] = {
              amountNotNumeric: true
            };
          }
        }
				if (shouldAmountExceedsExistingValueErrorBeShown) {
          if (incorrectlyFilledLines[lineNumber]) {
            incorrectlyFilledLines[lineNumber].amountExceedsExistingValue = true;
          } else {
            incorrectlyFilledLines[lineNumber] = {
              amountExceedsExistingValue: true
            };
          }
        }
      });

      // Если в строке (в товаре) есть ошибка хоть в одной из подстрок,
      // то добавляем данные о товаре к объекту incorrectlyFilledLines
      if (incorrectlyFilledLines[lineNumber]) {
        incorrectlyFilledLines[lineNumber] = {
          ...incorrectlyFilledLines[lineNumber],
          line
        };
      }
    });

    if (Object.keys(incorrectlyFilledLines).length) {
      setCheckValuesModalData(incorrectlyFilledLines);
      setIsCheckValuesModalActive(true);
      return false
    } else {
      return true;
    }
  };

	// Проведение отгрузки
	const provide = async () => {
		const documentNumberInserted = checkDocumentNumberInserted();
		const employeeInserted = checkEmployeeInserted();
		const linesFilledCorrectly = checkLinesFilledCorrectly();

		if (documentNumberInserted && employeeInserted && linesFilledCorrectly) {
			setIsPreloaderActive(true);
			await editRealization(false).then((saved) => {
				if(!saved) return;

				axios.post("realizations/provide/" + id).then(() => {
					dispatch(fetchRealizationAsyncAction(id));
					toast.success("Перемещено на Основной склад", {
						position: 'bottom-right'
					});
				}).catch(() => {
					toast.error("Ошибка перемещения", {
						position: 'bottom-right'
					});
				});
			});
			setIsPreloaderActive(false);
		}
	};

	// Завершение отгрузки
	const setDone = () => {
		setIsPreloaderActive(true);
		axios.get("realizations/setDone/" + realizationItems[0].realization_id).then((res) => {
			router.push("/realizations");
			toast.success("Готово. Не забудьте оформить эту отгрузку в базе!", {
        position: 'bottom-right'
      });

			return res;
		}).catch((res) => {
			if (res.response.data.unavailableForShiftingGoods) {
				setUnavailableForShiftingGoods(res.response.data.unavailableForShiftingGoods);
				setUnavailableGoodsModalActive(true);
			}
			toast.error("Ошибка проведения отгрузки", {
        position: 'bottom-right'
      });
		}).finally(() => {
			setIsPreloaderActive(false);
		});
	};

	// Отмена отгрузки
	const deny = () => {
		alert("Отменить отгрузку?", "danger", 0, [
			{
				text: "Да",
				handler: function(){
					axios.post("realizations/provide/" + realizationItems[0].realization_id, {
						deny: 1
					}).then(() => {
						dispatch(fetchRealizationAsyncAction(id));
						toast.success("Отгрузка успешно отменена", {
							position: 'bottom-right'
						});
					}).catch(() => {
						toast.error("Ошибка отмены", {
							position: 'bottom-right'
						});
					});
				},
				needToCloseImmediately: true
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	};

	// Добавление файла к форме
	const attachFile = (e) => {
		// Сборка FormData
		const formData = new FormData();
		Object.values(e.target.files).forEach((file, index) => {
			formData.append("file_" + index, file);
		});

		axios.post("realizations/setFile/" + realizationItems[0].realization_id, formData, {
			headers: {
				"Content-type": "multipart/form-data",
			}
		}).then(response => {
			toast.success(response.data.files.length > 1 ? "Файлы загружены" : "Файл загружен", {
        position: 'bottom-right'
      });
			const fl = [...commentFiles, response.data.files];
			setRealizationCommentFilesAction(fl[0]);
		}).catch(() => {
			toast.error("Ошибка добавления файла", {
        position: 'bottom-right'
      });
		});
	};

	// Удаление файла
	const removeFile = (url) => {
		alert("Удалить файл?", "danger", 0, [
			{
				text: "Да",
				handler: function(){
					axios.post("realizations/removeFile", {filename: url}).then(() => {
						toast.success("Файл удален", {
							position: 'bottom-right'
						});
						const copy = structuredClone(commentFiles);
						copy.forEach((cp, ind) => {
							if(cp.url === url) copy.splice(ind, 1);
						});
						setRealizationCommentFilesAction(copy);
					});
				},
				needToCloseImmediately: true
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	};

	// Метод для отправки скриншота
	// const screenShot = async() => {
	// 	return true;
	// 	const canvas = await html2canvas(toScreenShot.current, {useCORS: true}).then(canv => {
	// 		const image = canv.toDataURL("image/png", 1.0);
	// 		axios.post("realizations/editRealizationThumb/" + data[0].realization_id, {thumb: image});
	// 	});
	// }

	// Метод изменения наименования
	const changeName = (index, name, value) => {
		if(name === "amounts" && realizationItems[index].name === "") return;
		
		const copy = structuredClone(realizationItems);
		
		// Проверка дублирующей позиции
		let error = false;
		copy.forEach(cp => {
			if(cp[name] === value) error = true;
		});
		
		// Вывод ошибки, если это не группа
		if(error && name !== "b_group"){
			toast.error("Такой товар уже есть в списке!", {
				position: 'bottom-right'
			});
			copy.splice(index, 1);
		} else copy[index][name] = value;

		// Установка соответствующих значений остальных параметров (группа и артикул/наименование)
		if (name === 'name') {
			const plantArticle = plantsArticles.find((article) => article.good === value);
			copy[index].article = plantArticle.value;
			copy[index].b_group = groups.find((group) => group.id === plantArticle.b_group_id).value;
		}
		if (name === 'article') {
			const plantName = plantsNames.find((plantName) => plantName.article === value);
			copy[index].name = plantName.value;
			copy[index].b_group = groups.find((group) => group.id === plantName.b_group_id).value;
		}
		
		dispatch(setRealizationItemsAction(copy));

		// Если товар выбран (наименование определено), то запрашиваем данные с полей
		if (copy[index].name) {
      dispatch(setRealizationInitialAmountsAsyncAction({
				name: copy[index].name,
				date: documentDate
			}));
    }
	};

	// Изменение позиций
	const onChangePos = (index, name, value) => {
		const copy = structuredClone(realizationItems);
		
		// Изменение остатков на полях в таблице
		// if(!isNaN(parseInt(copy[index.index].amounts[index.ind]["amount"])) && name === "amount"){
		// 	if(isNaN(parseInt(copy[index.index].amounts[index.ind]["amount"]))) copy[index.index].amounts[index.ind]["amount"] = 0;
		// 	if(!isNaN(parseInt(copy[index.index].userPositions[index.ind]["amount"])))
		// 		copy[index.index].amounts[index.ind]["amount"] = parseInt(copy[index.index].amounts[index.ind]["amount"]) + parseInt(copy[index.index].userPositions[index.ind]["amount"]);
		// 	if(!isNaN(parseInt(value)))
		// 		copy[index.index].amounts[index.ind]["amount"] = parseInt(copy[index.index].amounts[index.ind]["amount"]) - parseInt(value);
		// }
		
		// Изменение стоимости услуги или просто позиции
		// if(name === "address_amount") copy[index.index].amounts[index.ind].address = value;
		// else copy[index.index].userPositions[index.ind][name] = value;

		copy[index.index].userPositions[index.ind][name] = value;
		
		dispatch(setRealizationItemsAction(copy));
	};
	
	// Запись бригадира
	const setEmployee = (name, value) => {
		const copy = structuredClone(realizationItems);
		copy[0].employee = value;
		dispatch(setRealizationItemsAction(copy));
		dispatch(setRealizationEmployeeAction(value));
	};

	// Скролл вниз при раскрытии списка с растениями внизу страницы
	const scrollPlus = useRef(null);
	const scrollCustomSelect = (index) => {
		if(realizationItems.length - 1 - index < 4)
			scrollPlus.current.scrollIntoView({behavior: "smooth"});
	};

	// Новая строка
	const addLine = () => {
		const copy = structuredClone(realizationItems);
		copy.push({
			name: "",
			totalAmount: 0,
			amounts: [{
				amount: "",
				address: ""
			}],
			userPositions: [{
				amount: "",
				address: ""
			}]
		});
		dispatch(setRealizationItemsAction(copy));
		setTimeout(() => {
			scrollCustomSelect(copy.length - 1);
		}, 200);
	};

	// Удаление строки
	const deleteLine = (index) => {
		alert("Удалить строку?", "danger", 0, [
			{
				text: "Да",
				handler: function(){
					const copy = structuredClone(realizationItems);
					copy[index.index].amounts.splice(index.i, 1);
					copy[index.index].userPositions.splice(index.i, 1);
					if(!copy[index.index].amounts.length) copy.splice(index.index, 1);
					dispatch(setRealizationItemsAction(copy));
				},
				needToCloseImmediately: true
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	};

	// Функция сортировки растений
	const sortPlants = (name) => {
		setSortDir(!sortDir);
		
		const pl = structuredClone(realizationItems);
		
		pl.sort((a, b) => {
			if(a[name] === b[name]) return 0;
			else if(!sortDir === true) return a[name] > b[name] ? 1 : -1;
			else return a[name] < b[name] ? 1 : -1;
		});
		
		dispatch(setRealizationItemsAction(pl));
		setSorted(name);
	};

	// Получить список наименований для селекта для вновь добавленной строки (в самом низу)
  const filterSelectComponentOptions = (selectComponentName, selectedGroupId) => {
    // Отсеиваем те товары, которые уже есть в инвентаризации
    const filteredPlants = plantsNames
      .filter((plant) => !itemsNames.includes(plant.name))
      .sort((a, b) => {
        const res = sortStringValues(a.value, b.value);
        return res;
      });
    const filteredArticles = filteredPlants.map((plant) => ({
      id: plant.b_group_id,
      name: plant.article,
      value: plant.article
    }));

    // Если в новой строке группа уже выбрана, то фильтруем список для селекта ещё и по группе
    if (selectedGroupId && groups.length) {
      const filteredByGroupPlants = filteredPlants
        .filter((plant) => plant.b_group_id === selectedGroupId)
        .sort((a, b) => {
          const res = sortStringValues(a.value, b.value);
          return res;
        });
      const filteredByGroupArticles = filteredByGroupPlants.map((plant) => ({
        id: plant.b_group_id,
        name: plant.article,
        value: plant.article
      }));

      switch(selectComponentName) {
        case 'name':
          return filteredByGroupPlants;
        case 'article':
          return filteredByGroupArticles;
        default:
          return;
      };
    }

    switch(selectComponentName) {
      case 'name':
        return filteredPlants;
      case 'article':
        return filteredArticles;
      default:
        return;
    };
  };

	const requestForDocumentDetails = async () => {
		setIsPreloaderActive(true);
		await dispatch(fetchRealizationAsyncAction(id));
		setIsPreloaderActive(false);
	};

	useEffect(() => {
		requestForDocumentDetails();
	}, [id]);

	useEffect(() => {
		if (!groups.length) {
      dispatch(fetchGroups());
    }
		if (!addressList.length) {
			dispatch(fetchAddresses());
		}
	}, []);

	return (
		<>
			<div className={tcl.floatPanel + " " + tcl.controlsPanel}>
				{
					realizationItems.length && provided === 0 && userLevel > 1 && (
						<span
							className="roundIcon dangerIcon material-icons"
							onClick={deleteRealization}
							title="Удалить отгрузку"
						>
							delete_sweep
						</span>
					)
				}
				
				{
					realizationItems.length && (
						<span
							className="roundIcon material-icons"
							onClick={() => router.push("/FILES/" + realizationItems.filename).then(() => router.push("/realizations/edit/" + realizationItems.realization_id))}
							title="Скачать"
						>
							download
						</span>
					)
				}
				
				{
					realizationItems.length && provided === 0 && userLevel > 1
						? <span
								className="roundIcon material-icons"
								onClick={() => alert("Провести в Системе?", "default", 0, [
									{
										text: "Да",
										handler: function(){
											provide();
										},
										needToCloseImmediately: true
									},
									{
										text: "Нет",
										handler: function(){
											alert("", "default", 1);
										}
									}
								])}
								title="Провести в Системе"
							>
								checklist
							</span>
						: userLevel > 1 && (
							<span
								className="roundIcon dangerIcon material-icons"
								onClick={deny}
								title="Отменить"
							>
								undo
							</span>
						)
				}
				
				{
					realizationItems.length && provided === 0 && userLevel > 1
					? (
						<span className="roundIcon material-icons" onClick={() => alert("Сохранить?", "default", 0, [
							{
								text: "Да",
								handler: function(){
									editRealization();
								},
								needToCloseImmediately: true
							},
							{
								text: "Нет",
								handler: function(){
									alert("", "default", 1);
								}
							}
						])} title="Сохранить">save</span>
					)
					: userLevel > 1 && (
						<span className="roundIcon material-icons" onClick={() => alert("Провести в Базе?", "default", 0, [
							{
								text: "Да",
								handler: function(){
									setDone();
								},
								needToCloseImmediately: true
							},
							{
								text: "Нет",
								handler: function(){
									alert("", "default", 1);
								}
							}
						])} title="Провести в Базе">done</span>
					)
				}
				
				<span
					className="roundIcon material-icons"
					onClick={() => setModalIsActive(true)}
					title="Примечание"
				>
					maps_ugc
				</span>
				<span
					className="roundIcon material-icons"
					onClick={() => window.open("/faq/realization", "_blank")}
					title="Инструкция"
				>
					quiz
				</span>
			</div>
			
			<div className="stickyContainer" ref={toScreenShot}>
				{/* <p style={{margin: ".3rem 0 0 .5rem"}}>{(realizationItems.length && realizationItems.number) || ''}</p> */}
				{/* <p style={{margin: ".3rem 0 .3rem .5rem"}}>{(realizationItems.length && realizationItems.customer) || ''}</p> */}
				
				<table className={tableClasses.join(" ")} style={realizationItems.length && realizationItems.length === 0 ? {display: "none"} : {}}>
					<thead className={tcl.top0 + " theadBordered thead-dark"}>
						<tr>
							<th colSpan="2" className={tcl.borderNone}>
								Отгрузка (реализация) №&nbsp;
								{
									realizationItems.length && realizationItems && provided === 0
										? (
											<Input
												type="text"
												name="documentNumber"
												autocomplete="off"
												placeholder="№"
												value={documentNumber}
												setValue={(name, value) => dispatch(setRealizationDocumentNumberAction(value))}
											/>
										)
										: documentNumber
								}
							</th>
							<th className={tcl.borderNone}>
								<span>от </span>
								<span onClick={handleInactiveDocumentDateClick}>
									{documentDate.split(' ')[0]}
								</span>
							</th>
							<th>
								{
									realizationItems.length && author &&
									<>
										Автор:&nbsp;
										{author}
										<br/>
									</>
								}
								Бригадир:&nbsp;
								{
									realizationItems.length && (
										<Input
											type="text"
											name="employee"
											value={employee}
											setValue={setEmployee}
										 	placeholder="Бригадир"
											title={employee}
										/>
									)
								}
							</th>
							<th colSpan="3">Текущие остатки в Системе:</th>
							<th rowSpan="3">Кол-во<br/>по заказу</th>
							<th colSpan="2">Переместить на Основной склад</th>
							{realizationItems.length && provided === 0 && <th rowSpan="3" className={tcl.borderNone}/>}
						</tr>
						<tr>
							<th rowSpan="2">№</th>
							<th
								rowSpan="2"
								className={sorted === "name" ? "sort sorted" : "sort"}
								onClick={() => sortPlants("name")}
							>
								<span>Название</span>
							</th>
							<th
								rowSpan="2"
								className={sorted === "article" ? "sort sorted" : "sort"}
								onClick={() => sortPlants("article")}
							>
								<span>Артикул</span>
							</th>
							<th rowSpan="2">Группа</th>
							<th rowSpan="2">Всего:</th>
							<th colSpan="2">В том числе:</th>
							<th rowSpan="2">Кол-во</th>
							<th rowSpan="2">Со склада</th>
						</tr>
						<tr>
							<th>Кол-во</th>
							<th>Адрес</th>
						</tr>
					</thead>
					<tbody>
						{
							realizationItems.length && realizationItems.map((line, index) =>
								<Fragment key={index.toString()}>
									{
										line.amounts.map((ln, i) =>
											<tr key={i.toString()} className={line.isError ? tcl.error : ""}>
												{
													i === 0 &&
													<>
														<td rowSpan={line.amounts.length}>{index + 1}</td>
														<td rowSpan={line.amounts.length} onClick={() => scrollCustomSelect(index)}>
															{
																line.name === ""
																? (
																	<CustomSearchSelect
																		inputName='name'
																		defaultValue={line.name}
																		options={filterSelectComponentOptions('name', line.b_group_id)}
																		onChange={(value) => changeName(index, 'name', value)}
																		placeholder='Наименование'
																	/>
																)
																: line.name
															}
														</td>
														<td rowSpan={line.amounts.length} onClick={() => scrollCustomSelect(index)}>
															{
																line.name === ""
																? (
																	<CustomSearchSelect
																		inputName='article'
																		defaultValue={line.article}
																		options={filterSelectComponentOptions('article', line.b_group_id)}
																		onChange={(value) => changeName(index, 'article', value)}
																		placeholder='Артикул'
																	/>
																)
																: line.article
															}
														</td>
														<td rowSpan={line.amounts.length}>
															{
																line.name === ""
																? (
																	<CustomSearchSelect
																		inputName='b_group'
																		defaultValue={line.b_group}
																		options={groups}
																		onChange={(value) => changeName(index, 'b_group', value)}
																		placeholder='Группа'
																	/>
																)
																: line.b_group
															}
														</td>
														<td rowSpan={line.amounts.length} className={tcl.right}>
															{!line.isUsl && new Intl.NumberFormat("ru-RU").format(line.totalAmount)}
														</td>
													</>
												}
												
												<td className={tcl.right}>
													{!line.isUsl && new Intl.NumberFormat("ru-RU").format(ln.amount)}
												</td>
												<td>{!line.isUsl && ln.address}</td>
												
												{
													i === 0 && (
														<td rowSpan={line.amounts.length} className={tcl.right}>
															{line.orderAmount ? new Intl.NumberFormat("ru-RU").format(line.orderAmount) : 0}
														</td>
													)
												}
												
												<td className={tcl.right}>
													{
														provided === 0
															? (
																<Input
																	type="number" name="amount"
																	placeholder="Кол-во"
																	value={line.userPositions[i].amount}
																	setValue={onChangePos}
																	currentIndex={{index: index, ind: i}}
																/>
															)
															: new Intl.NumberFormat("ru-RU").format(line.userPositions[i].amount)
													}
												</td>
												
												<td>
													{
														provided === 0 && !ln.address
															? (
																<CustomSearchSelect
																	inputName='address'
																	defaultValue={ln.address}
																	options={getDisplayedAddresses(index)}
																	onChange={(value) => onChangePos({index: index, ind: i}, 'address', value)}
																	placeholder='Адрес'
																/>
															)
															: (
																<span>{ln.address}</span>
															)
													}
												</td>
												
												{
													provided === 0 && (
														<>
															<td className="iconed">
															<span
																className="roundIcon material-icons"
																onClick={() => deleteLine({index: index, i: i})}
																title="Удалить строку">delete_outline</span>
															</td>
														</>
													)
												}
											</tr>
										)
									}
								</Fragment>
							)
						}
						{
							realizationItems.length && provided === 0 && (
								<tr className="adding" ref={scrollPlus}>
									<td className="iconed">
										<span
											className="roundIcon material-icons"
											onClick={addLine}
											title="Добавить наименование"
										>
											add
										</span>
									</td>
								</tr>
							)
						}
					</tbody>
				</table>
			</div>
			
			<Modal isActive={modalIsActive} setIsActive={setModalIsActive}>
				{
					realizationItems.length && done
						? (
							<>
								<textarea placeholder="Примечание" onChange={(e) => setRealizationCommentAction(e.target.value)}>
									{comment}
								</textarea>
								<input type="file" name="files[]" onChange={attachFile} multiple/>
							</>
						)
						: comment
				}
				{
					commentFiles.length && commentFiles.map((file) =>
						<div className="fileIconGroup">
							<span
								className="material-icons remove"
								title="Удалить"
								onClick={() => removeFile(file.url)}
							>
								cancel
							</span>
							<span
								className="material-icons file"
								onClick={() => file.ext === "pdf" ? window.open(file.url, "_blank") : router.push(file.url).then(() => router.push(router.asPath))}
							>
								description
							</span>
							<span
								className="name"
								onClick={() => file.ext === "pdf" ? window.open(file.url, "_blank") : router.push(file.url).then(() => router.push(router.asPath))}
							>
								{file.name}
							</span>
						</div>
					)
				}
			</Modal>

			{
				unavailableForShiftingGoods.length && (
					<Modal isActive={unavailableGoodsModalActive} setIsActive={handleUnavailableGoodsModalCloseButtonClick}>
						{
							unavailableForShiftingGoods.map((good) => (
								<div>
									<h5 className={styles.title}>
										{'Невозможно выполнить отгрузку указанного товара из-за недостаточного кол-ва в Бизнес.ру: '}
										<span
											className="roundIcon material-icons"
											onClick={handleCopyUnavailableGoodsLinesNumbers}
											title="Скопировать строки"
										>
											copy
										</span>
									</h5>
									<table className={styles.table}>
										<tr className={styles.row}>
											<td className={styles.cell}>Номер строки</td>
											<td className={styles.cell}>Наименование</td>
											<td className={styles.cell}>Склад</td>
											<td className={styles.cell}>Доступное кол-во для отгрузки</td>
										</tr>
										<tr>
											<td className={styles.cell}>
												{realizationItems.findIndex((item) => String(item.good_id) === String(good.good_id)) + 1}
											</td>
											<td className={styles.cell}>
												<span>{good.name}</span>
											</td>
											<td className={styles.cell}>
												<span>{good.storage}</span>
											</td>
											<td className={styles.cell}>
												<span>{good.total_storage_amount}</span>
											</td>
										</tr>
									</table>
								</div>
							))
						}
					</Modal>
				)
			}

			<RealizationCheckValuesModal
        data={checkValuesModalData}
        isActive={isCheckValuesModalActive}
        setIsActive={setIsCheckValuesModalActive}
      />

			<Preloader isActive={isPreloaderActive}/>
		</>
	);
};

export default RealizationEdit;
