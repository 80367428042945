import {useContext, useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {fetchSupplies} from 'redux/slices/business/businessSlice';

import Button from 'components/ButtonComponent';

import cl from 'styles/components/cabinet/UsersTable.module.scss';

import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';
import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';

const Index = () => {
	const navigate = useNavigate();
	const { search } = useLocation();

	const dispatch = useDispatch();
	const {supplies} = useSelector((state) => state.business);
	
	const { setCrumbs, setMenuCondition } = useContext(AppContext);	
	// Состояние с формами
	const [forms, setForms] = useState([]);
	
	useEffect(() => {
		// Сброс условия меню
		setMenuCondition({});
	}, [search]);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: DocumentTypeNameMap[DocumentType.SUPPLY], url: ''}
		]);
	}, []);

	useEffect(() => {
		dispatch(fetchSupplies());
	}, []);

	return (
		<>
			{!supplies.data.length && <p>&nbsp; Новых поступлений нет</p>}
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th colSpan="3"/>
						<th>
							<Button
								type='white'
								onClick={() => window.open(AppRoute.Archive.SUPPLIES)}
							>
								Архив
							</Button>
						</th>
						<th>
							<Button
								type='white'
								onClick={() => window.open('/faq/supply')}
							>
								Инструкция
							</Button>
						</th>
					</tr>
					<tr>
						<th>№</th>
						<th>Тип</th>
						<th>Дата</th>
						<th>Склад</th>
						<th/>
					</tr>
				</thead>
				<tbody>
					{
						supplies.data.map((form, index) =>
							<tr key={index.toString()}>
								<td>
									<span>{form.document_number}</span>
								</td>
								<td>
									<span>Поступление</span>
								</td>
								<td>
									<span>{form.document_date.slice(0, -6)}</span>
								</td>
								<td>
									<span>{form.storage ? form.storage : 'НЕ ВЫБРАНО'}</span>
								</td>
								<td>
									<span
										className={cl.icon + ' material-icons'}
										title="Посмотреть"
										onClick={() => navigate(`${AppRoute.Document.SUPPLIES}/${form.supply_id}`)}>
										visibility
									</span>
								</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</>
	);
}

export default Index;
