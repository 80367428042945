import {createSlice} from '@reduxjs/toolkit';

import {
  fetchRealizationAsyncAction,
  fetchArchiveRealizationsListAsyncAction,
  parseFileAndFetchDataAsyncAction,
  setRealizationInitialAmountsAsyncAction
} from './realizations-api-actions';

const initialState = {
  realizationsList: [],
  document: {
    "document_number": "798",
    "document_date": "10.12.2024 20:00",
    "number": "Расходная накладная № 798 от 03 декабря 2024г.",
    "customer": "Частное лицо Виталий Кальник",
    "realization_id": 37,
    "provided": 0,
    "filename": "realization_10.12.2024_17:02.xlsx",
    "employee": "qwe",
    "author": "Сахаров Михаил Германович",
    "comment": null,
    "files": [],
    "done": 0,
    "id": 1805,
    "good_id": 0,
    "name": "Сосна обыкновенная, ком 500-600",
    "b_group": "Хвойные",
    "article": "62038",
    "totalAmount": 0,
    "orderAmount": 5,
    "amounts": [
      {
        "amount": "",
        "address": "",
        "field_id": 0
      }
    ],
    "userPositions": [
      {
        "amount": "",
        "address": ""
      }
    ]
  },
  head: {},
  documentNumber: '',
  documentDate: '',
  documentNewDate: '',
  provided: undefined,
  employee: '',
  author: '',
  comment: '',
  done: undefined,
  commentFiles: [],
  realizationItems: []
};

export const realizations = createSlice({
  name: 'realizations',
  initialState,
  reducers: {
    resetState: (state) => {
      state.head = {};
      state.documentNumber = '';
      state.documentDate = '';
      state.documentNewDate = '';
      state.provided = undefined;
      state.employee = '';
      state.author = '';
      state.comment = '';
      state.done = undefined;
      state.commentFiles = [];
      state.realizationItems = [];
    },
    setRealizationItemsAction: (state, action) => {
      state.realizationItems = action.payload;
    },
    setRealizationDocumentNumberAction: (state, action) => {
      state.documentNumber = action.payload;
    },
    setRealizationDocumentDateAction: (state, action) => {
      state.documentDate = action.payload;
    },
    setRealizationDocumentNewDateAction: (state, action) => {
      state.documentNewDate = action.payload;
    },
    setRealizationCommentFilesAction: (state, action) => {
      state.commentFiles = action.payload;
    },
    setRealizationCommentAction: (state, action) => {
      state.comment = action.payload;
    },
    setRealizationEmployeeAction: (state, action) => {
      state.employee = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(parseFileAndFetchDataAsyncAction.fulfilled, (state, action) => {
        state.head = action.payload.head;
        state.realizationItems = action.payload.result;
        state.documentNumber = action.payload.head.number.split(' ')[3];
        state.documentDate = action.payload.head.document_date;
      })
      .addCase(fetchRealizationAsyncAction.fulfilled, (state, action) => {
        state.realizationItems = action.payload.result;
        state.commentFiles = action.payload.result[0].files;
        state.documentNumber = action.payload.result[0].document_number;
        state.documentDate = action.payload.result[0].document_date;
        state.provided = action.payload.result[0].provided;
        state.employee = action.payload.result[0].employee;
        state.author = action.payload.result[0].author;
        state.comment = action.payload.result[0].comment;
        state.done = action.payload.result[0].done;
      })
      .addCase(setRealizationInitialAmountsAsyncAction.fulfilled, (state, action) => {
        const plantName = action.payload.plantName;
        const amounts = action.payload.data.amounts;
        const documentItems = [...state.realizationItems];
        const selectedItemIndex = documentItems.findIndex((item) => item.name === plantName);

        if (amounts.length) {
          const userPositions = amounts.map((amountsItem) => ({
            address: amountsItem.address,
            amount: ''
          }));

          documentItems[selectedItemIndex].amounts = amounts;
          documentItems[selectedItemIndex].userPositions = userPositions;
          documentItems[selectedItemIndex].totalAmount = amounts.reduce((res, item) => res + Number(item.amount), 0);
          state.realizationItems = documentItems;
        }
      })
      .addCase(fetchArchiveRealizationsListAsyncAction.fulfilled, (state, action) => {
        state.realizationsList = action.payload.result;
      });
  }
});

export const {
  resetState,
  setRealizationItemsAction,
  setRealizationDocumentNumberAction,
  setRealizationDocumentDateAction,
  setRealizationDocumentNewDateAction,
  setRealizationCommentFilesAction,
  setRealizationCommentAction,
  setRealizationEmployeeAction
} = realizations.actions;
