import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

import {setInventoryDataAction} from 'redux/slices/inventory/inventorySlice';
import {getGoods} from 'redux/slices/good/selectors';
import {getGroups} from 'redux/slices/business/selectors';

import TableRow from 'components/table-row/table-row';
import AddNewLineButton from 'components/add-new-line-button/add-new-line-button';

const TableBody = ({
  paginationData,
  setPaginationData,
  firstItemIndex,
  displayedItems
}) => {
  const dispatch = useDispatch();
  const {id} = useParams();

  const goods = useSelector(getGoods);
  const groupsIdsFromGoods = goods.map((good) => good.b_group_id);
  const groups = useSelector(getGroups).filter((group) => groupsIdsFromGoods.includes(group.id));

  const provided = useSelector((state) => state.inventory.inventory.provided);
  const storage = useSelector((state) => state.inventory).inventory.storage;
  const allAddresses = useSelector((state) => state.inventory).addresses.data;
  const addresses = allAddresses.filter((address) => address.storage === storage).sort((a, b) => {
    const ADDRESS_REG_EXP = /^\d+/;
    const prevValue = a.value;
    const nextValue = b.value;

    if (ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return prevValue.match(ADDRESS_REG_EXP)[0] - nextValue.match(ADDRESS_REG_EXP)[0];
    }
    if (ADDRESS_REG_EXP.test(prevValue) && !ADDRESS_REG_EXP.test(nextValue)) {
      return -1;
    }
    if (!ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return 1;
    }
    
    return 0;
  });

  // Номер подсвечиваемой строки, когда пользователь задублировал
  // название при добавлении нового товара и программа по нажатию
  // кнопки перекинула его к уже созданному товару с таким же именем
  const [outlinedRow, setOutlinedRow] = useState(null);

  // Выборка данных из стейта селекторами
  const inventoryItems = useSelector((state) => state.inventory).inventory.data;

  // Новая строка
  const addLine = () => {
    const copy = structuredClone(inventoryItems);
    const emptyLine = {
      name: "",
      uchet: 0,
      fact: 0,
      spread: 0,
      total_uchet: 0,
      total_fact: 0,
      datas: [
        {
          spread: "",
          amount_fact: "",
          address_fact: "",
          amount_uchet: "",
          address_uchet: "",
        },
      ],
      inventory_id: id
    };

    const updatedItems = copy.length ? [
      ...copy.slice(0, paginationData.itemsPerPage * paginationData.activePage - 1),
      emptyLine,
      ...copy.slice(paginationData.itemsPerPage * paginationData.activePage - 1)
    ] : [emptyLine];

    dispatch(setInventoryDataAction(updatedItems));
  };
  
  return (
    <tbody>
      {displayedItems.map((item, index) => (
        <TableRow
          key={item.id}
          item={item}
          index={firstItemIndex + index}
          storage={storage}
          goods={goods}
          groups={groups}
          addresses={addresses}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          outlinedRow={outlinedRow}
          setOutlinedRow={setOutlinedRow}
        />
      ))}

      {/* Добавить наименование */}
      {
        provided === 0 && (
          <AddNewLineButton title='Добавить наименование' addLine={addLine}/>
        )
      }
      <tr style={{ height: "1px" }} />
    </tbody>
  );
};

export default TableBody;
