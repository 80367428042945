export const DocumentType = {
  CHARGE: 'charge',
  POSTING: 'posting',
  SUPPLY: 'supply'
};

export const DocumentTypeNameMap = {
  [DocumentType.CHARGE]: 'Списание',
  [DocumentType.POSTING]: 'Оприходование',
  [DocumentType.SUPPLY]: 'Поступление'
};
