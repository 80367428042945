import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiRoute} from 'constants/endpoints';

export const fetchArchiveRealizationsListAsyncAction = createAsyncThunk(
  'realizations/fetchRealizationsList',
  async (_args, {extra: api}) => {
    const {data} = await api.get(ApiRoute.Realizations.GET_ARCHIVE_REALIZATIONS_LIST);

    return data;
  }
);

export const parseFileAndFetchDataAsyncAction = createAsyncThunk(
  'realizations/parseFileAndFetchData',
  async (formData, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Realizations.PARSE_FILE_AND_FETCH_DATA, formData);

    return data;
  }
);

export const fetchRealizationAsyncAction = createAsyncThunk(
  'realizations/fetchRealization',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Realizations.GET_REALIZATION}/${id}`);

    return data;
  }
);

export const setRealizationInitialAmountsAsyncAction = createAsyncThunk(
  'realizations/setNewDocumentInitialAmounts',
  async (body, {extra: api}) => {
    // const {data} = await api.post(ApiRoute.Fields.SET_INITIAL_AMOUNTS, body);
    const {data} = await api.post(ApiRoute.Fields.SET_INITIAL_HISTORY_AMOUNTS, body);

    return {data, plantName: body.name};
  }
);
